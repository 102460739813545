<template>
  <v-form>
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          v-model="record.country_id"
          :items="countries"
          item-text="name"
          item-value="id"
          label="Country Code"
        ></v-autocomplete>
      </v-col>

      <v-col cols="6">
        <v-text-field label="Bank Code" v-model="record.code"></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field label="Bank Name" v-model="record.name"></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field label="Account" v-model="record.account"></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    record: {},
    loader: false,
    date: new Date().toISOString().substr(0, 10)
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.loader = true;
      this.record.country_id = 1;
      this.$emit("data", this.record);
    }
  }
};
</script>